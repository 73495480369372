.burger-btn{
  width: 74px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.burger{
  position: relative;
  width: 38px;
  height: 16px;
  flex-shrink: 0;
  display: none;
  transform: scale(.75);

  @media (max-width: 1280px) {
    display: block;
  }

  span{
    position: absolute;
    left: 0;
    width: 100%;
    display: block;
    background: var(--black);
    height: 1px;
    transition: .3s;
    &:nth-child(1){
      top: 0;
    }
    &:nth-child(2){
      top: 50%;
      transform-origin: center;
    }
    &:nth-child(3){
      top: 100%;
    }
  }

  &.active{
    span{
      &:nth-child(1){
        transform: rotate(44deg) translate(5px, 5px);
      }
      &:nth-child(2){
        transform: scale(0);
      }
      &:nth-child(3){
        transform: rotate(-44deg) translate(6px, -5px);
      }
    }
  }
}