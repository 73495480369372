.freeInstall{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    background: linear-gradient(-90deg,  rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 75%);
    
    @media (max-width: 640px) {
      background: linear-gradient(0deg,  rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 70%);
    }
    @media (max-width: 350px) {
      background: linear-gradient(0deg,  rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 60%);
    }
  }
}